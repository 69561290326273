import gsap from 'gsap'

export const Accordion = {
  selector: '.Accordion',
  init() {
    const accordion = document.querySelector(this.selector)
    const button = accordion.querySelector('.toggle-accordion')
    const content = accordion.querySelector('.accordion-content')

    let open = false

    const accordionAnimation = gsap.timeline({ defaults: { ease: 'power2.out' } })
    accordionAnimation.to(content, {
      height: 0,
      duration: 0.5,
    })

    button.addEventListener('click', () => {
      if (open) {
        accordionAnimation.play()
        accordion.classList.add('closed')
      } else {
        accordionAnimation.reverse()
        accordion.classList.remove('closed')
      }
      open = !open
    })
  },
}
