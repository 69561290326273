import { MainHero } from './mainHero'
import { Header } from './header'
import { Slider } from './slider'
import { Teaser } from './teaser'
import { Accordion } from './accordion'
import { Footer } from './footer'
import { DetailHero } from './detailHero'
import { InfoBubble } from './infobubble'
import { CTA } from './cta'

export const components = [MainHero, Header, Slider, Teaser, Accordion, Footer, DetailHero, InfoBubble, CTA]
