export const CTA = {
  selector: ".cta-button",
  init() {
    const button = document.querySelector(this.selector);

    document.addEventListener(
      "scroll",
      () => {
        button.classList.toggle("inactive", scrollY < 100);
      },
      { passive: true }
    );
  },
};
