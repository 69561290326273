import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import { breakpoints } from '../general/breakpoints'

gsap.registerPlugin(ScrollTrigger)

export const Teaser = {
  selector: '.Teaser',
  init() {
    const teasers = document.querySelectorAll('.Teaser')

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    teasers.forEach((teaser, i) => {
      const teaserContent = teaser.querySelector('.teaser-content')
      const teaserInformation = teaser.querySelector('.teaser-information')
      const teaserGradient = teaser.querySelector('.teaser-gradient')

      const teaserImage = teaser.querySelector('.teaser-image')
      const teaserImageHeight = teaserImage.getBoundingClientRect().height

      const teaserIcon = teaser.querySelector('.teaser-icon > use')
      const teaserIconHeight = teaserIcon.getBoundingClientRect().height

      if (innerWidth < breakpoints.sm) {
        gsap.set(teaserInformation, {
          transform: `translateY(${teaserImageHeight - teaserIconHeight}px)`,
        })
      }

      if (!isSafari) {
        teaserContent.style.setProperty('clip-path', `url(#mask-${i + 1})`)

        const svg = teaser.querySelector('.mask-svg')
        const clipPath = svg.querySelector('clipPath')
        const path = clipPath.querySelector('path')

        const contentBox = teaserContent.getBoundingClientRect()
        const pathBox = {
          width: svg.clientWidth,
          height: svg.clientHeight,
        }

        const breakpointsArray = Object.values(breakpoints)

        const scales = svg.getAttribute('data-scale').split(' ')

        let scale = parseFloat(scales[0])

        for (let i = breakpointsArray.length - 1; i >= 0; i--) {
          if (innerWidth > breakpointsArray[i]) {
            scale = parseFloat(scales[i + 1])
            break
          }
        }

        const x = contentBox.width / 2 - (pathBox.width * scale) / 2
        const y = contentBox.height / 2 - (pathBox.height * scale) / 2

        const origin = svg.getAttribute('data-origin')
        clipPath.setAttribute('style', `transform: translate(${x}px, ${y}px) scale(${scale});`)

        gsap.set(clipPath, { transformOrigin: origin })

        const teaserAnimation1 = gsap.timeline({ paused: true })

        const yOffset = 1.1 * (pathBox.height / 2 + contentBox.height / 2)

        teaserAnimation1.from(teaserImage, {
          y: yOffset,
        })

        ScrollTrigger.create({
          animation: teaserAnimation1,
          trigger: teaserContent,
          start: 'top center',
          end: 'center-=10% center',
          onLeave: () => {
            teaserAnimation2.play()
          },
          scrub: 0.5,
          once: true,
        })

        const teaserAnimation2 = gsap.timeline({ paused: true, defaults: { ease: 'power2.out' } })

        teaserAnimation2.set(teaserContent, { backgroundColor: 'transparent' })

        teaserAnimation2.to(clipPath, { scale: 25, duration: 2 }, 'p')
        teaserAnimation2.from(
          teaserImage,
          {
            scale: 0.8,
            duration: 1,
          },
          'p'
        )
        teaserAnimation2.from(
          teaserInformation.children,
          {
            y: 80,
            opacity: 0,
            duration: 2,
            stagger: -0.2,
          },
          'p=-1.0'
        )

        teaserAnimation2.from(
          teaserGradient,
          {
            opacity: 0,
            duration: 1,
          },
          'p=-1.0'
        )
      } else {
        if (innerWidth > breakpoints.sm) {
          if (i !== 0) {
            teaser.style.setProperty('margin-top', '16px')
          }
        }

        gsap.from(teaser, {
          opacity: 0,
          yPercent: 20,
          duration: 1.5,
          ease: 'power2.out',
          scrollTrigger: {
            trigger: teaser,
            start: 'top center',
            end: 'bottom center',
          },
        })
      }
    })
  },
}
