import Swiper from "swiper";
import { Autoplay } from "swiper/modules";

Swiper.use([Autoplay]);

export const Slider = {
  selector: ".Slider",
  init() {
    const sliders = document.querySelectorAll(".Slider");

    sliders.forEach((slider) => {
      const swiper = new Swiper(slider.querySelector(".swiper"), {
        speed: 400,
        spaceBetween: 100,
        loop: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
      });

      const paginationItems = slider.querySelectorAll(".pagination-item");
      const amountOfSlides = paginationItems.length;

      swiper.on("slideChange", () => {
        const active = (swiper.activeIndex - 1) % amountOfSlides;
        paginationItems.forEach((item, i) => {
          if (i == active) item.classList.add("active");
          else item.classList.remove("active");
        });
      });

      swiper.autoplay.start();
    });
  },
};
