import gsap from "gsap";

export const Footer = {
  selector: ".Footer",
  init() {
    const footer = document.querySelector(this.selector);
    const svg = footer.querySelector(".footer-background");

    const footerAnimation = gsap.timeline({ paused: true });
    footerAnimation.from(svg, {
      opacity: 0,
      y: 100,
    });

    document.addEventListener(
      "scroll",
      () => {
        const bodyHeight = document.body.offsetHeight;
        const bottomDistance = bodyHeight - (scrollY + innerHeight);

        if (bottomDistance < 600) {
          footerAnimation.play();
        }
      },
      { passive: true }
    );
  },
};
