import gsap from 'gsap'

export const InfoBubble = {
  selector: '.InfoBubble',
  init() {
    const infobubbles = document.querySelectorAll('.InfoBubble')
    const infotriggers = document.querySelectorAll('.info-trigger')

    infobubbles.forEach((bubble, i) => {
      const trigger = infotriggers[i]
      const triggerParent = trigger.parentNode
      const triggerLeft = trigger.children[0].getBoundingClientRect().right
      const triggerParentLeft = triggerParent.getBoundingClientRect().left

      const bubbleLeft = triggerLeft - triggerParentLeft
      const offset = 20
      const bubbleWidth = bubble.getBoundingClientRect().width

      bubble.style.setProperty('--tw-translate-x', `${bubbleLeft - bubbleWidth + offset}px`)
    })

    infotriggers.forEach((trigger, i) => {
      trigger.addEventListener('click', () => {
        infobubbles[i].classList.toggle('open')
      })
    })
  },
}
