import gsap from 'gsap'

export const MainHero = {
  selector: '.MainHero',
  init() {
    const arrow = document.querySelector('.MainHero .arrow')
    gsap.from('.MainHero h1, .MainHero h1 + svg', {
      y: 50,
      opacity: 0,
      duration: 1,
      stagger: 0.2,
      ease: 'power2.out',
      onComplete: () => {
        arrow.classList.add('animation')
      },
    })
  },
}
