import gsap from 'gsap'

export const DetailHero = {
  selector: '.DetailHero',
  init() {
    gsap.from('.DetailHero .anim', {
      y: 50,
      opacity: 0,
      duration: 1,
      stagger: 0.1,
      ease: 'power2.out',
    })
  },
}
