import gsap from 'gsap'
import { breakpoints } from '../general/breakpoints'

export const Header = {
  selector: '.Header',
  init() {
    const header = document.querySelector('.Header')
    const nav = header.querySelector('nav')
    const mainLogo = document.querySelector('.main-logo.hide-on-scroll')
    // Header on Scroll
    setInterval(() => {
      if (scrollY > 0) {
        header.classList.add('scroll')
        mainLogo.classList.add('hidden')
      } else {
        header.classList.remove('scroll')
        mainLogo.classList.remove('hidden')
      }
    }, 100)

    //Navigation Open/Close
    const toggleMenuButtons = header.querySelectorAll('#open-nav, #close-nav')
    const navAnimation = gsap.timeline({ paused: true, defaults: { ease: 'power1.out' } })
    let isNavOpened = false

    navAnimation.to(
      nav,
      {
        clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
        duration: 1,
        pointerEvents: 'all',
      },
      'p'
    )

    navAnimation.from(
      '.nav-list > li',
      {
        opacity: 0,
        y: 50,
        stagger: 0.1,
        duration: 0.7,
      },
      'p+=0.3'
    )

    navAnimation.from(
      '.nav-button',
      {
        opacity: 0,
        y: 30,
        duration: 0.5,
        pointerEvents: 'all',
      },
      '-=0.5'
    )

    navAnimation.from(
      '.nav-header',
      {
        opacity: 0,
        y: 20,
        stagger: 0.1,
        duration: 0.5,
      },
      '-=0.8'
    )

    toggleMenuButtons.forEach((button) => {
      button.addEventListener('click', () => {
        isNavOpened ? navAnimation.reverse() : navAnimation.play()
        isNavOpened = !isNavOpened
      })
    })

    // Hoverlinks
    if (innerWidth > breakpoints.xl) {
      const navLinks = nav.querySelectorAll('.nav-list > li')
      const hoverImages = nav.querySelectorAll('.hover-images > .hover-image')

      let activeLink = 0

      navLinks.forEach((link, i) => {
        link.addEventListener('mouseover', () => {
          hoverImages[i].classList.add('active')
          activeLink = i
        })
        link.addEventListener('mouseout', () => {
          hoverImages[i].classList.remove('active')
        })
      })

      const maxDistanceFromOrigin = 0.8

      nav.addEventListener('mousemove', (e) => {
        const transform = ((e.clientY / innerHeight - 0.5) / 50) * maxDistanceFromOrigin * 100
        hoverImages[activeLink].style.setProperty('transform', `translateY(${transform * 100}%)`)
      })
    }
  },
}
